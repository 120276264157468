import React, { useState, useEffect } from "react";
import Logo from "../../images/Logo.jpg";
import { Input } from "semantic-ui-react";
import { ReactComponent as Icon } from "../../images/Spinner.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
// import * as AWS from "aws-sdk/global";
// import Forgot from "../Forgot/Forgot";
import "./Login.css";
import { useRecoilState } from "recoil";
import { auth as authAtom, authenticated as authenticatedAtom } from "../Atoms";

const Login = ({ authenticated, setAuthenticated }) => {
	const [auth, setAuth] = useRecoilState(authAtom);
	const [MFA, setMFA] = useState(false);
	const [MFACode, setMFACode] = useState("");
	const [challengeAnswer, setChallengeAnswer] = useState("");
	const [TOTPSetup, setTOTPSetup] = useState(false);
	const [loginState, setLoginState] = useState({
		username: "",
		password: "",
	});
	const [str, setStr] = useState("");
	const [cognitoUser, setCognitoUser] = useState("");

	const [forgotPW, setForgotPW] = useState(false);

	const [incorrect, setIncorrect] = useState(false);

	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (loginState.username === "" || loginState.password === "") {
		} else setLoading(true);
		try {
			const user = await Auth.signIn(loginState.username, loginState.password);
			const groups =
				user?.signInUserSession.accessToken.payload["cognito:groups"];
			const idToken = user?.signInUserSession?.idToken?.jwtToken;
			if (groups) {
				if (groups[0] === "Admin") {
					setAuth(idToken);
					setAuthenticated(true);
					setLoading(false);
				}
			} else {
				setLoading(false);
				toast.error("Access Denied.");
				Auth.signOut();
			}
			// if (
			// 	user.challengeName === "MFA_SETUP" ||
			// 	user.challengeName === undefined
			// ) {
			// 	setTOTPSetup(true);
			// 	Auth.setupTOTP(user).then((code) => {
			// 		setStr(
			// 			"otpauth://totp/AWSCognito:" +
			// 				user.username +
			// 				"?secret=" +
			// 				code +
			// 				"&issuer=AWSCognito"
			// 		);
			// 	});
			// }

			// if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
			// 	setMFA(true);
			// }
		} catch (error) {
			toast.error(error.message);
			setLoading(false);
		}
	};

	const verifyToken = async (e) => {
		e.preventDefault();
		const user = await Auth.currentAuthenticatedUser();
		// console.log(user);
		Auth.verifyTotpToken(user, challengeAnswer)
			.then((data) => {
				console.log(data);
				// don't forget to set TOTP as the preferred MFA method
				Auth.setPreferredMFA(user, "TOTP");
				setLoading(false);
				setTOTPSetup(false);
				// ...
			})
			.catch((e) => {
				console.log(e);
				// Token is not verified
			});
	};

	const verifyMFA = async (e) => {
		e.preventDefault();
		try {
			const user = await Auth.signIn(loginState.username, loginState.password);
			const loggedUser = await Auth.confirmSignIn(
				user, // Return object from Auth.signIn()
				MFACode, // Confirmation code
				"SOFTWARE_TOKEN_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
			);
			// console.log(loggedUser);
			setAuthenticated(true);
		} catch (e) {
			console.log(e);
			alert(e.message);
		}
	};

	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setLoginState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	if (TOTPSetup)
		return (
			<div>
				<form className="login">
					<div className="login-container">
						{/* <QRCode value={str} /> */}
						<Input
							style={{ marginTop: "20px" }}
							name="MFACode"
							placeholder="Enter MFA Code"
							value={challengeAnswer}
							id="login-password"
							onChange={(e) => setChallengeAnswer(e.target.value)}
							required
						/>
						<button
							onClick={verifyToken}
							style={{ marginTop: "20px" }}
							className="sign-in-button hvr-shutter-in-vertical"
						>
							SUBMIT
						</button>
					</div>
				</form>
			</div>
		);

	if (MFA)
		return (
			<div>
				<form className="login">
					<div className="login-container">
						<Input
							name="MFACode"
							placeholder="Enter MFA Code"
							value={MFACode}
							id="login-password"
							onChange={(e) => setMFACode(e.target.value)}
							required
						/>
						<button
							style={{ marginTop: "20px" }}
							onClick={verifyMFA}
							className="sign-in-button hvr-shutter-in-vertical"
						>
							SUBMIT
						</button>
					</div>
				</form>
			</div>
		);

	return (
		<>
			<div style={{ height: "100vh", overflow: "hidden" }}>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<form className="login" onSubmit={handleSubmit}>
					<img
						className="login-logo"
						src={Logo}
						alt="login-logo"
						style={{ width: "20vw", height: "auto" }}
					/>
					<div
						className="login-container"
						style={{ transform: "translateY(-10vh)" }}
					>
						<h3>Sign in to your account</h3>
						<h4>
							Email <span style={{ color: "#efb758" }}>*</span>
						</h4>
						<Input
							placeholder="Email..."
							name="username"
							value={loginState.email}
							id="login-email"
							onChange={handleChange}
							required
						/>
						<h4>
							Password <span style={{ color: "#efb758" }}>*</span>
						</h4>
						<Input
							name="password"
							type="password"
							placeholder="Enter your password..."
							value={loginState.password}
							id="login-password"
							onChange={handleChange}
							required
						/>

						{incorrect ? (
							<h6 className="incorrect">
								The username or password you have entered is incorrect
							</h6>
						) : null}
						{/* <h6 style={{ display: "hidden" }} className="forgot" onClick={() => setForgotPW(true)}>
							Forgot password? Reset password
						</h6> */}
						<div className="login-create" style={{ marginTop: "5vh" }}>
							<button
								// onClick={handleClick}
								className="sign-in-button hvr-shutter-in-vertical"
							>
								{loading ? "SIGNING IN" : "SIGN IN"}
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default Login;
