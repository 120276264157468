import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import UserList from "./UserList";
import { useQuery } from "react-query";
import axios from "axios";
import {
	auth as authAtom,
	submitting as submittingAtom,
	currentAccount as currentAccountAtom,
	addUserModal as addUserModalAtom,
	resetPasswordModal as resetPasswordModalAtom,
	selectedUser as selectedUserAtom,
	departmentList as departmentListAtom,
} from "./Atoms";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import { useRecoilState } from "recoil";
import useUsers from "../Hooks/useUsers";
import Box from "@material-ui/core/Box";
import { Auth } from "aws-amplify";
import EditUser from "./EditUser";
import EditBrand from "./EditBrand";
import { ReactComponent as Spinner } from "../images/Spinner.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	container: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginTop: "1vh",
	},
	buttons: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
		margin: "20px",
	},
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function UserPool({ id }) {
	const classes = useStyles();
	const [auth, setAuth] = useRecoilState(authAtom);
	// const [currentAccount, setCurrentAccount] = useRecoilState(
	// 	currentAccountAtom
	// );
	//Ares
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [currentAccount, setCurrentAccount] = useRecoilState(
		currentAccountAtom
	);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const [resetPasswordModalOpen, setResetPasswordModalOpen] = useRecoilState(
		resetPasswordModalAtom
	);
	const [userSelected, setUserSelected] = useState(false);
	const [submitting, setSubmitting] = useRecoilState(submittingAtom);
	const [departments, setDepartments] = useState([]);
	// const [departmentList, setDepartmentList] = useRecoilState(
	// 	departmentListAtom
	// );

	const users = useUsers();

	async function getBrands() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const response = await axios.get(`${process.env.REACT_APP_API}/brands`, {
			headers: {
				Authorization: idToken,
			},
		});
		console.log(response.data);
		return response.data;
	}
	// const brands = useQuery(`brands`, getBrands);
	// const dep = useQuery("departments", getDepartments, {
	// 	enabled: users?.data.length,
	// });

	// const [users, setUsers] = useState([]);

	async function getUsers() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		console.log(idToken);
		const data = await axios.get(`${process.env.REACT_APP_API}/users`, {
			headers: {
				Authorization: idToken,
			},
		});
		// setUsers(data.data);
		return data.data;
	}

	// async function getDepartments() {
	// 	const user = await Auth.currentAuthenticatedUser();
	// 	const idToken = user.signInUserSession.idToken.jwtToken;
	// 	const data = await axios.get(`${process.env.REACT_APP_API}/departments`, {
	// 		headers: {
	// 			Authorization: idToken,
	// 		},
	// 	});
	// 	console.log(data);
	// 	console.log(users);
	// 	let u = users?.data.map((user) => {
	// 		return user.Attributes[0].Value;
	// 	});
	// 	let d;
	// 	if (data.data && departments.length === 0) {
	// 		d = [
	// 			...new Set(
	// 				data.data.map((user) => {
	// 					return user.email;
	// 				})
	// 			),
	// 		];
	// 		let diff = u.filter((x) => !d.includes(x));
	// 		let extra = diff.map((e) => {
	// 			return {
	// 				department_name: "Unassigned",
	// 				email: e,
	// 			};
	// 		});
	// 		let deps = [...data.data, ...extra];
	// 		console.log(deps);
	// 		setDepartments(deps);
	// 		setDepartmentList(data);
	// 		return deps;
	// 	}
	// }

	// useEffect(() => {
	// 	let mounted = true;
	// 	if (mounted) {
	// 		getUsers();
	// 	}
	// 	return () => {
	// 		mounted = false;
	// 	};
	// }, []);

	// useEffect(() => {
	// 	let mounted = true;
	// 	if (mounted) {
	// 		console.log(users);
	// 		if (users.status === "success") {
	// 			getDepartments();
	// 		}
	// 	}
	// 	return () => {
	// 		mounted = false;
	// 	};
	// }, [users.data]);

	const [addUser, setAddUser] = useRecoilState(addUserModalAtom);
	const [resetPassword, setResetPassword] = useRecoilState(
		resetPasswordModalAtom
	);

	const handleAddUserOpen = () => {
		setAddUser(true);
	};

	const handleAddUserClose = () => {
		setAddUser(false);
	};

	const handleResetPasswordOpen = () => {
		setResetPasswordModalOpen(true);
	};

	const handleResetPasswordClose = () => {
		setResetPassword(false);
	};

	useEffect(() => {
		if (selectedUser !== "") {
			setUserSelected(true);
		}
	}, [selectedUser]);

	// const { status, isLoading, error, data } = useUsers();

	/********************************************************* */

	if (users.isLoading) {
		return (
			<div>
				<Spinner />
			</div>
		);
	}
	if (users.error) {
		console.log(users.error.message);
	}

	return (
		<div className={classes.root}>
				<>
					{/* <EditBrand brands={brands.data} />
					<EditUser departments={departments} /> */}
					<div className={classes.container}>
						<div style={{ minWidth: "95vw", marginBottom: "5vh" }}>
							<UserList users={users.data} />
						</div>
					</div>
				</>
		</div>
	);
}

export default UserPool;
